import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import { CustomDateTime } from '../../utils/DateTimeUtils';

type NextAppointmentTooltipComponentProps = {
    nextWorkshopAppointment?: string;
};
const NextAppointmentTooltipComponent = ({ nextWorkshopAppointment }: NextAppointmentTooltipComponentProps) => {
    const date = nextWorkshopAppointment;
    const hasWorkshopAppointment = !!date;
    return (
        <OverlayTrigger
            placement={Tooltip.RIGHT}
            overlay={
                <Tooltip allowOnTouch textAlignment="left" width={200}>
                    <span className="text-bold">
                        <FormattedMessage id="vehicle_status.datatabs.workshop_details.basic_info.title" />
                    </span>
                    <FormattedMessage id="vehicle_status.datatabs.workshop_details.basic_info.message" />
                    {hasWorkshopAppointment && (
                        <>
                            <br />
                            {/* eslint-disable-next-line max-len */}
                            <FormattedMessage id="vehicle_status.datatabs.workshop_details.next_workshop_appointment" />
                            <CustomDateTime value={date} showWeekDay={false} />
                        </>
                    )}
                    <br />
                    <br />
                    <FormattedMessage id="vehicle_status.datatabs.workshop_details.workshop_actions_message" />
                </Tooltip>
            }
        >
            <span
                data-testid="nextWorkshopAppointmentInfoIcon"
                className="rioglyph rioglyph-info-sign text-size-16"
                style={{ marginLeft: 8 }}
            />
        </OverlayTrigger>
    );
};

export default NextAppointmentTooltipComponent;
