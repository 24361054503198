import Button from '@rio-cloud/rio-uikit/Button';
import { useState } from 'react';
import DataTabsSettingsDialogComponent, { VehicleStatusDataSectionKey } from './DataTabsSettingsDialogComponent';

type DataTabsSettingsDialogContainerProps = {
    savedDataTabSettings: VehicleStatusDataSectionKey[];
    onSaveDataTabSettingsDialog: Function;
};

const DataTabsSettingsDialogContainer = ({
    savedDataTabSettings,
    onSaveDataTabSettingsDialog,
}: DataTabsSettingsDialogContainerProps) => {
    const [isToShowDialog, setIsToShowDialog] = useState(false);

    return (
        <div className={'display-flex flex-column'}>
            {!isToShowDialog && (
                <Button
                    iconOnly
                    className={'btn btn-default margin-top-auto'}
                    onClick={() => {
                        setIsToShowDialog(true);
                    }}
                >
                    <span className="rioglyph rioglyph-cog" data-testid="datatabs-settings-button" />
                </Button>
            )}
            {isToShowDialog && (
                <DataTabsSettingsDialogComponent
                    isToShowDialog={isToShowDialog}
                    onClickCloseDialog={() => setIsToShowDialog(false)}
                    onClickSaveDialog={onSaveDataTabSettingsDialog}
                    savedDataTabSettings={savedDataTabSettings}
                />
            )}
        </div>
    );
};

export default DataTabsSettingsDialogContainer;
