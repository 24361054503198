import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { MultiselectOption } from '@rio-cloud/rio-uikit/types';

type StatusFilterComponentProps = {
    isLoading: boolean;
    title: string;
    placeholder: string;
    tooltip?: string;
    filterOptions: MultiselectOption[];
    onSelect: Function;
};

// Disabling the no-shadow as this is a bug from eslint with typescript
// eslint-disable-next-line no-shadow
export const enum Criticality {
    CRITICAL = 'CRITICAL',
    WARNING = 'WARNING',
    OK = 'OK',
}

// Disabling the no-shadow as this is a bug from eslint with typescript
// eslint-disable-next-line no-shadow
export const enum SyncStatus {
    SYNCED = 'SYNCED',
    RECENTLY_SYNCED = 'RECENTLY_SYNCED',
    NOT_SYNCED = 'NOT_SYNCED',
}

const getAllFilterOptionsIds = (filterOptions: MultiselectOption[]): string[] => {
    return filterOptions.map((filter: MultiselectOption) => filter.id);
};

const FilterComponent = ({
    isLoading,
    title,
    placeholder,
    tooltip,
    filterOptions,
    onSelect,
}: StatusFilterComponentProps) => {
    const filterOptionsWithSelection = filterOptions.map((filter: MultiselectOption) => ({
        ...filter,
        disabled: isLoading,
    }));
    const onChange = (selectedOptionsArray: any[]) => {
        let newSelectedOptionsArray = [...selectedOptionsArray];
        if (newSelectedOptionsArray.length === 0) {
            // whenever user tries to deselect the last item, select all items instead
            newSelectedOptionsArray = getAllFilterOptionsIds(filterOptions);
        }
        // all options selected is the same of no filters to be applied
        onSelect(newSelectedOptionsArray.length === filterOptions.length ? [] : newSelectedOptionsArray);
    };
    return (
        <div className={'display-flex flex-column min-width-200'} data-testid={`filter-component-${title}`}>
            <div className={'display-flex align-content-start gap-10'}>
                <span className={'text-color-highlight-light margin-bottom-10'}>{title}</span>
                {tooltip && (
                    <OverlayTrigger
                        placement={Tooltip.RIGHT}
                        overlay={
                            <Tooltip allowOnTouch textAlignment="left" width={200}>
                                {tooltip}
                            </Tooltip>
                        }
                    >
                        <span
                            data-testid="syncStatusInfoIcon"
                            className="rioglyph rioglyph-info-sign text-size-16"
                            style={{ marginTop: 2 }}
                        />
                    </OverlayTrigger>
                )}
            </div>
            <Multiselect
                disabled={isLoading}
                placeholder={placeholder}
                options={filterOptionsWithSelection}
                onChange={(selectedOptionsArray: any[]) => onChange(selectedOptionsArray)}
                showSelectedItemIcon
            />
        </div>
    );
};

export default FilterComponent;
