import axios from 'axios';
import { useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { config } from '../../config';
import { useAppSelector } from '../../configuration/setup/hooks';
import { CsvHeader, GetVehicleStatusDataCsv } from '../../generated/GeneratedApiTypes';
import { removeUndefinedOrEmptyArrayAttributes } from '../../utils/commonUtils';
import { getAssetTreeAccountIds, getAssetTreeAssetIds } from '../app/assetTreeSlice';
import { sendMessage, SHOW_NOTIFICATION } from '../app/MessageHandler';
import { Criticality, SyncStatus } from './FilterComponent';

type RequestBody = GetVehicleStatusDataCsv.GetVehicleStatusDataCsv.RequestBody;
type ResponseBodyCsv = GetVehicleStatusDataCsv.GetVehicleStatusDataCsv.ResponseBody;

type useFetchVehicleStatusDataCsv = {
    criticalityStatus: Criticality[];
    syncStatus: SyncStatus[];
    dataTabsSettings: VehicleStatusDataSectionKey[];
};

const VehicleStatusDataSections = {
    VEHICLE_INFO: 'VEHICLE_INFO',
    MAINTENANCE: 'MAINTENANCE',
    BRAKE_LININGS: 'BRAKE_LININGS',
    DTC_DIAGNOSTIC_MEMORY: 'DTC_DIAGNOSTIC_MEMORY',
    DTC_ACTIVE_ERRORS: 'DTC_ACTIVE_ERRORS',
    WARNING_MESSAGES: 'WARNING_MESSAGES',
} as const;
export type VehicleStatusDataSectionKey = (typeof VehicleStatusDataSections)[keyof typeof VehicleStatusDataSections];

const KeyHeadersForCsv = {
    VEHICLE_NAME: 'VEHICLE_NAME',
    VIN: 'VIN',
    CUSTOMER: 'CUSTOMER',
    LICENSE_PLATE: 'LICENSE_PLATE',
    APPOINTMENTS: 'APPOINTMENTS',
    OPENED_WORKSHOP_ACTIONS: 'OPENED_WORKSHOP_ACTIONS',
    VEHICLE_NOTES: 'VEHICLE_NOTES',
    OVERDUE_COMPONENTS: 'OVERDUE_COMPONENTS',
    ALMOST_DUE_COMPONENTS: 'ALMOST_DUE_COMPONENTS',
    CRITICAL_BRAKE_LININGS: 'CRITICAL_BRAKE_LININGS',
    WARNING_BRAKE_LININGS: 'WARNING_BRAKE_LININGS',
    CRITICAL_WARNING_MESSAGES: 'CRITICAL_WARNING_MESSAGES',
    WARNING_WARNING_MESSAGES: 'WARNING_WARNING_MESSAGES',
    OTHER_WARNING_MESSAGES: 'OTHER_WARNING_MESSAGES',
    CRITICAL_ACTIVE_DTC_DM1: 'CRITICAL_ACTIVE_DTC_DM1',
    WARNING_ACTIVE_DTC_DM1: 'WARNING_ACTIVE_DTC_DM1',
    OTHER_ACTIVE_DTC_DM1: 'OTHER_ACTIVE_DTC_DM1',
    CRITICAL_ACTIVE_DTC_DM4: 'CRITICAL_ACTIVE_DTC_DM4',
    WARNING_ACTIVE_DTC_DM4: 'WARNING_ACTIVE_DTC_DM4',
    OTHER_ACTIVE_DTC_DM4: 'OTHER_ACTIVE_DTC_DM4',
} as const;

// @ts-ignore
const useFetchVehiclesStatusDataCsv = ({
    criticalityStatus,
    syncStatus,
    dataTabsSettings,
}: useFetchVehicleStatusDataCsv) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [vehiclesStatusDataCsv, setVehiclesStatusDataCsv] = useState<Blob | undefined>(undefined);

    const intl = useIntl();
    const csvHeaders: CsvHeader[] = getCsvHeaders(intl);

    // get values from asset tree
    const assetIds = useAppSelector(getAssetTreeAssetIds);
    const accountIds = useAppSelector(getAssetTreeAccountIds);
    const url = `${config.backend.BACKEND_URI}/get-vehicle-status-data-csv`;
    const body: RequestBody = {
        asset_ids: assetIds,
        account_ids: accountIds,
        status: criticalityStatus,
        sync_status: syncStatus,
        csv_headers: getCsvHeadersFromSelectedSections(csvHeaders, dataTabsSettings),
        sections_to_include: dataTabsSettings,
    };
    removeUndefinedOrEmptyArrayAttributes(body);

    const fetchVehiclesStatusDataCsv = () => {
        setIsLoading(true);
        axios
            .post<ResponseBodyCsv>(url, body, {
                responseType: 'blob',
            })
            .then((response) => {
                setVehiclesStatusDataCsv(response.data);
            })
            .catch((error) => {
                console.error('Error fetching get-vehicle-status-data-csv', error);
                sendMessage({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        title: intl.formatMessage({ id: 'vehicle_status.csv_button.failed' }),
                        description: intl.formatMessage({ id: 'vehicle_status.csv_button.failed.description' }),
                        type: 'ERROR',
                    },
                });
            })
            .finally(() => setIsLoading(false));
    };

    return {
        isLoading,
        fetchVehiclesStatusDataCsv,
        vehiclesStatusDataCsv,
    };
};

const requiredCsvHeaderKeys: string[] = [
    KeyHeadersForCsv.VEHICLE_NAME,
    KeyHeadersForCsv.VIN,
    KeyHeadersForCsv.CUSTOMER,
    KeyHeadersForCsv.LICENSE_PLATE,
];

const getCsvHeadersFromSelectedSections = (
    csvHeaders: CsvHeader[],
    selectedSectionsFromDataTabsSettings: VehicleStatusDataSectionKey[],
): CsvHeader[] => {
    if (selectedSectionsFromDataTabsSettings.length === 0) {
        return csvHeaders;
    }
    const selectedCsvKeys = selectedSectionsFromDataTabsSettings.flatMap((section) =>
        mapSelectedSectionToCsvHeaderKeys(section),
    );

    return csvHeaders.filter(
        (csvHeader) =>
            selectedCsvKeys.includes(csvHeader.header_key!) || requiredCsvHeaderKeys.includes(csvHeader.header_key!),
    );
};

const getCsvHeaders = (intl: IntlShape) => {
    return [
        {
            header_key: KeyHeadersForCsv.VEHICLE_NAME,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.vehicle_name' }),
        },
        {
            header_key: KeyHeadersForCsv.VIN,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.vin' }),
        },
        {
            header_key: KeyHeadersForCsv.CUSTOMER,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.customer' }),
        },
        {
            header_key: KeyHeadersForCsv.LICENSE_PLATE,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.license_plate' }),
        },
        {
            header_key: KeyHeadersForCsv.APPOINTMENTS,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.appointments' }),
        },
        {
            header_key: KeyHeadersForCsv.OPENED_WORKSHOP_ACTIONS,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.opened_workshop_actions' }),
        },
        {
            header_key: KeyHeadersForCsv.VEHICLE_NOTES,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.vehicle_notes' }),
        },
        {
            header_key: KeyHeadersForCsv.OVERDUE_COMPONENTS,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.overdue_components' }),
        },
        {
            header_key: KeyHeadersForCsv.ALMOST_DUE_COMPONENTS,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.almost_due_components' }),
        },
        {
            header_key: KeyHeadersForCsv.CRITICAL_BRAKE_LININGS,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.critical_brake_linings' }),
        },
        {
            header_key: KeyHeadersForCsv.WARNING_BRAKE_LININGS,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.warning_brake_linings' }),
        },
        {
            header_key: KeyHeadersForCsv.CRITICAL_WARNING_MESSAGES,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.critical_warning_messages' }),
        },
        {
            header_key: KeyHeadersForCsv.WARNING_WARNING_MESSAGES,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.warning_warning_messages' }),
        },
        {
            header_key: KeyHeadersForCsv.OTHER_WARNING_MESSAGES,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.other_warning_messages' }),
        },
        {
            header_key: KeyHeadersForCsv.CRITICAL_ACTIVE_DTC_DM1,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.critical_dtc_dm1' }),
        },
        {
            header_key: KeyHeadersForCsv.WARNING_ACTIVE_DTC_DM1,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.warning_dtc_dm1' }),
        },
        {
            header_key: KeyHeadersForCsv.OTHER_ACTIVE_DTC_DM1,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.other_dtc_dm1' }),
        },
        {
            header_key: KeyHeadersForCsv.CRITICAL_ACTIVE_DTC_DM4,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.critical_dtc_dm4' }),
        },
        {
            header_key: KeyHeadersForCsv.WARNING_ACTIVE_DTC_DM4,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.warning_dtc_dm4' }),
        },
        {
            header_key: KeyHeadersForCsv.OTHER_ACTIVE_DTC_DM4,
            header_value: intl.formatMessage({ id: 'vehicle_status.csv_button.header.other_dtc_dm4' }),
        },
    ];
};

const mapSelectedSectionToCsvHeaderKeys = (selectedSection: VehicleStatusDataSectionKey): string[] => {
    switch (selectedSection) {
        case VehicleStatusDataSections.VEHICLE_INFO:
            return [
                KeyHeadersForCsv.APPOINTMENTS,
                KeyHeadersForCsv.OPENED_WORKSHOP_ACTIONS,
                KeyHeadersForCsv.VEHICLE_NOTES,
            ];
        case VehicleStatusDataSections.MAINTENANCE:
            return [KeyHeadersForCsv.OVERDUE_COMPONENTS, KeyHeadersForCsv.ALMOST_DUE_COMPONENTS];
        case VehicleStatusDataSections.BRAKE_LININGS:
            return [KeyHeadersForCsv.CRITICAL_BRAKE_LININGS, KeyHeadersForCsv.WARNING_BRAKE_LININGS];
        case VehicleStatusDataSections.DTC_DIAGNOSTIC_MEMORY:
            return [
                KeyHeadersForCsv.CRITICAL_ACTIVE_DTC_DM4,
                KeyHeadersForCsv.WARNING_ACTIVE_DTC_DM4,
                KeyHeadersForCsv.OTHER_ACTIVE_DTC_DM4,
            ];
        case VehicleStatusDataSections.DTC_ACTIVE_ERRORS:
            return [
                KeyHeadersForCsv.CRITICAL_ACTIVE_DTC_DM1,
                KeyHeadersForCsv.WARNING_ACTIVE_DTC_DM1,
                KeyHeadersForCsv.OTHER_ACTIVE_DTC_DM1,
            ];
        case VehicleStatusDataSections.WARNING_MESSAGES:
            return [
                KeyHeadersForCsv.CRITICAL_WARNING_MESSAGES,
                KeyHeadersForCsv.WARNING_WARNING_MESSAGES,
                KeyHeadersForCsv.OTHER_WARNING_MESSAGES,
            ];
    }
};

export default useFetchVehiclesStatusDataCsv;
