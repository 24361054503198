import { FormattedMessage } from 'react-intl';

interface CsvButtonComponentProps {
    fetchVehiclesStatusDataCsv: Function;
    isProcessing: boolean;
}

const CsvButtonComponent = ({ fetchVehiclesStatusDataCsv, isProcessing }: CsvButtonComponentProps) => (
    <button
        className={'btn btn-default margin-top-auto'}
        onClick={() => fetchVehiclesStatusDataCsv()}
        disabled={isProcessing}
    >
        {isProcessing && (
            <span data-testid="csv-loading-spinner" className="rioglyph rioglyph-spinner spinning" aria-hidden="true" />
        )}
        <FormattedMessage id="vehicle_status.csv_button.title" />
    </button>
);

export default CsvButtonComponent;
