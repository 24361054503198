/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';

declare const APP_VERSION: string;

if (import.meta.env.PROD) {
    // version and environment are defined in the vite.config.ts
    const release = APP_VERSION;
    const environment = import.meta.env.MODE;

    // should have been called before using it here
    // ideally before even rendering your react app
    Sentry.init({
        dsn: config.sentryToken,
        environment,
        release,
    });
}

export const reportErrorToSentry = (...args: [any, any?]) => {
    Sentry.captureException(...args);
};
