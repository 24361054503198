import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';

const PAGE_SIZE_VALUES = [60, 80, 100];

type VehicleDataTabsPaginationComponentProps = {
    isLoading: boolean;
    pageSize?: number;
    pageNumber?: number;
    totalPages?: number;
    onPageSizeChange: Function;
    onPageNumberChange: Function;
};

const PaginationControlsComponent = ({
    isLoading,
    pageSize,
    pageNumber,
    totalPages,
    onPageSizeChange,
    onPageNumberChange,
}: VehicleDataTabsPaginationComponentProps) => {
    if (!pageSize || !pageNumber || !totalPages) {
        return null;
    }
    return (
        <div className="display-flex flex-wrap margin-top-20 flex-1-0" data-testid={'pagination-controls-component'}>
            <PageSizeComponent disabled={isLoading} pageSize={pageSize} onPageSizeChange={onPageSizeChange} />
            <PageNumberComponent
                disabled={isLoading}
                pageNumber={pageNumber}
                totalPages={totalPages}
                onPageNumberChange={onPageNumberChange}
            />
        </div>
    );
};

type PageSizeComponentProps = {
    disabled: boolean;
    pageSize: number;
    onPageSizeChange: Function;
};

const PageSizeComponent = ({ disabled, pageSize, onPageSizeChange }: PageSizeComponentProps) => {
    const items = PAGE_SIZE_VALUES.map((item) => ({
        value: item,
        onSelect: () => onPageSizeChange(item),
    }));
    return <ButtonDropdown disabled={disabled} title={pageSize} items={items} />;
};

type PageNumberComponentProps = {
    disabled: boolean;
    pageNumber: number;
    totalPages: number;
    onPageNumberChange: Function;
};

const PageNumberComponent = ({ disabled, pageNumber, totalPages, onPageNumberChange }: PageNumberComponentProps) => {
    const renderPageNumberOption = (pn: number) => (
        <li
            key={`page_number_${pn}`}
            className={`${pn === pageNumber ? 'active' : ''}${disabled ? ' disabled' : ''}`}
            onClick={() => onPageNumberChange(pn)}
        >
            <span>{pn}</span>
        </li>
    );

    const numbersRendered = [];
    if (pageNumber > 1) {
        numbersRendered.push(renderPageNumberOption(1));
    }
    if (pageNumber > 3) {
        numbersRendered.push(<span key="key_first_dots">...</span>);
    }
    if (pageNumber > 2) {
        numbersRendered.push(renderPageNumberOption(pageNumber - 1));
    }
    numbersRendered.push(renderPageNumberOption(pageNumber));
    if (totalPages > pageNumber + 1) {
        numbersRendered.push(renderPageNumberOption(pageNumber + 1));
    }
    if (totalPages > pageNumber + 2) {
        numbersRendered.push(<span key="key_second_dots">...</span>);
    }
    if (totalPages !== pageNumber) {
        numbersRendered.push(renderPageNumberOption(totalPages));
    }
    return (
        <ul className={'pagination flex-1-0'}>
            <li
                className={`${pageNumber === 1 || disabled ? 'disabled' : ''}`}
                onClick={() => pageNumber !== 1 && onPageNumberChange(pageNumber - 1)}
            >
                <span className={'rioglyph rioglyph-chevron-left'} />
            </li>
            {numbersRendered}
            <li
                className={`${pageNumber === totalPages || disabled ? 'disabled' : ''}`}
                onClick={() => pageNumber !== totalPages && onPageNumberChange(pageNumber + 1)}
            >
                <span className={'rioglyph rioglyph-chevron-right'} />
            </li>
        </ul>
    );
};

export default PaginationControlsComponent;
